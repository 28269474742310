<template>
	<div class="page">
		<div class="s2 p-card m-3">
			<div class="flex p-3 fs-16">
				<div class="flex-1 b">今日推品计划（{{ list.length }}）</div>
				<div class="pl-1">
					<a href="javascript:void(0)" class="btn-reset" @click="resetClick">重置</a>
				</div>
			</div>
			<div class="divider-dashed ml-3"/>
			<div class="b2">
				<no-data v-if="list.length===0" title="暂无推品数据" :show-button="false"/>
				<van-list v-model:loading="loading" :finished="finished" finished-text="" @load="getDataList">
					<draggable
							v-model="list"
							handle=".drag-item"
							animation="300"
							:force-fallback="true"
							@end="dragEnd"
					>
						<transition-group>
							<div v-for="(item,index) in list" :key="index" class="item">
								<!--                <div class="drag-item pointer icon-1" />-->
								<div class="pl-3">
									<div class="img"><img :src="item.pushImg">
										<div class="tag"><span>{{ index + 1 }}</span></div>
									</div>
								</div>
								<div class="flex-1">
									<div class="p1"><b>{{ item.pushTitile }}</b></div>
									<div class="p2">{{ item.pushDes }}</div>
									<div class="p3"><span>{{ item.pushDate }} {{ item.pushDateTime }} 推送</span></div>
								</div>
								<div class="p4 pointer ml-2">
									<div class="icon" @click="previewClick(item)"/>
									<div class="p-icon-delete mt-2" @click="deleteItem(item,index)"/>
								</div>
							</div>
						</transition-group>
					</draggable>
				</van-list>
			</div>
		</div>

		<div class="x-padding-bottom"/>
		<div class="p-footer h-end">
			<button class="btn auto" @click="handleCancel">取消</button>
			<button class="btn auto fill ml-3" @click="handleSubmit">保存</button>
		</div>

		<chat-list :id="id" v-model:show="dialog1"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueDraggableNext } from 'vue-draggable-next'
import chatList from '@/components/chatList/index.vue'
import {
	ApiGetBizTodayPushGroupPage,
	ApiGetUserTodayPushGroupPage,
	ApiUserTodayPushGroupSaveNew
} from '../../api/PushProduct'

export default {
	name: 'WelcomeEdit',
	components: { draggable: VueDraggableNext, chatList },
	data() {
		return {
			dialog1: false,
			list: [],
			listT: [],
			loading: false,
			finished: false,
			pageIndex: 1,
			pageSize: 15,
			id: ''
		}
	},
	computed: {
		...mapGetters(['globalData'])
	},
	created() {
	},
	methods: {
		async getDataList(reset) {
			this.loading = true
			try {
				let request = null
				if (reset) {
					request = ApiGetBizTodayPushGroupPage
					this.pageIndex = 1
				} else {
					request = ApiGetUserTodayPushGroupPage
				}
				const res = await request({
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					sortName: '',
					sortOrder: 0,
					officalId: this.$route.query.officalId
				})
				this.pageIndex++
				const list = res.dataList || []
				if (reset) {
					this.list = list
				} else {
					this.list = [...this.list, ...list]
				}
				if (this.list.length >= res.recordCount) {
					this.finished = true
				}
				this.listT = JSON.parse(JSON.stringify(this.list))
				this.loading = false
			} catch (e) {
				this.loading = false
				this.finished = true
				console.log('ApiGetBizTodayPushGroupPage', e)
			}
		},
		previewClick(item) {
			this.id = item.gId
			this.dialog1 = true
		},
		deleteItem(item, index) {
			this.list.splice(index, 1)
		},
		resetClick() {
			this.$Dialog.confirm({
				    title: '重置',
				    message: '是否确认重置？'
			    })
			    .then(() => {
				    // on confirm
				    this.getDataList(true)
			    })
			    .catch(() => {
				    // on cancel
			    })
		},
		dragEnd(e) {
			this.list[e.oldIndex].pushDateTime = this.listT[e.oldIndex].pushDateTime
			this.list[e.newIndex].pushDateTime = this.listT[e.newIndex].pushDateTime
		},
		handleCancel() {
			this.$store.commit('resetGlobalData')
			this.$router.go(-1)
		},
		async handleSubmit() {
			try {
				await ApiUserTodayPushGroupSaveNew({
					officalId: this.$route.query.officalId,
					gIds: this.list.map(item => item.gId)
				})
				this.$Toast('保存成功')
				this.$store.commit('resetGlobalData')
				this.$router.go(-1)
			} catch (e) {
				console.log(e)
			}
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";
</style>
